import React, { useContext } from 'react';
import { ThemeContext } from '../context/themeContext';

export const ThemeToggle = () => {
	const { theme, toggleTheme } = useContext(ThemeContext);

	return <div className="themetoggle">
		<input type="checkbox" id="theme-toggle" onChange={toggleTheme} checked={theme === 'light'} />
		<label htmlFor="theme-toggle" />
	</div>;
};
