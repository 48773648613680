import React from 'react';

import { ThemeContext } from '../context/themeContext';
import { ThemeToggle } from './themeToggle';

const Layout = ({ children }) => {
	return (
		<ThemeContext.Consumer>
			{({ theme }) => (
				<main data-theme={theme}>
					<ThemeToggle />
					{children}
				</main>
			)}
		</ThemeContext.Consumer>
	);
};
export default Layout;
