import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => {

	return (
		<Layout>
			<h1>Page not found</h1>
			<p>
				Whatever you are looking for is not here.<br />
				<span role="img" aria-label="Pensive emoji">
					😔
				</span>
				{process.env.NODE_ENV === 'development' ? (
					<>
						<br />
						Try creating a page in <code>src/pages/</code>.
						<br />
					</>
				) : null}
				<br />
				<Link to="/">Go home</Link>.
			</p>
		</Layout>
	);
};

export default NotFoundPage;
